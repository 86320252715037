import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    TableList,
    Button,
    Pagination,
    InputField,
    useAddToast,
    IconTextButton,
} from "@cortexglobal/rla-components";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { faTrash, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CubeLoader, ButtonDropdown } from "@cortexglobal/lens-components";
import ViewAmazonOrder from "./ViewAmazonOrder";
import ViewCreditOrder from "./ViewCreditOrder";

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const PanelBody = styled.div``;

const StyledPanel = styled(Panel)`
    padding: 0;
`;

const RewardOrderContainer = styled.div`
    padding-top: 2em;
`;

const BoldTd = styled.td`
    font-weight: bold;
`;

const HalfColumn = styled.div`
    width: 50%;
    display: inline-block;

    &:first-of-type {
        margin-right: 1em;
    }
`;

const DetailsTable = styled.table`
    border-spacing: 0;

    tr:nth-child(odd) {
        background: #f7f7f7;
    }

    td {
        padding: 1rem;
    }
`;

const CustomerNotesTable = styled.table`
    border-spacing: 0;

    tr {
        border-bottom: 1px solid #000000;
    }

    td {
        padding: 1rem;
    }
`;

const ViewRewardOrder = ({ match }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [rewardOrderData, setRewardOrderData] = useState();
    const addToast = useAddToast();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [customerNote, setCustomerNote] = useState("");
    const [customerNoteData, setCustomerNoteData] = useState();
    const [customerNoteLoading, setCustomerNoteLoading] = useState(true);
    const [actions, setActions] = useState([]);
    const [actionSlugs, setActionSlugs] = useState([]);

    const getCustomerNotes = (id) => {
        setCustomerNoteLoading(true);
        axios
            .get(`/api/v1/customers/${id}/note`)
            .then(({ data }) => {
                setCustomerNoteData(data.data);
                setCustomerNoteLoading(false);
            })
            .catch((e) => {
                console.log("e", e);
                addToast({
                    type: "alert",
                    content: "There was an error getting notes.",
                    showFor: 5000,
                });
                setCustomerNoteLoading(false);
            });
    };

    const actionFunction = (action) => {
        switch (action) {
            case "deny":
                return () => handleDeny();
            case "accept":
                return () => handleAccept();
            case "dispatch":
                return () => handleDispatch();
            case "resend":
                return () => handleResend();
        }
    };
    const generateActions = (actions) => {
        const newActions = [...actions].slice(1);
        if (!!newActions.length) {
            setActions(
                newActions.map((action) => ({
                    name: action.charAt(0).toUpperCase() + action.slice(1),
                    onClick: actionFunction(action),
                }))
            );
        }
    };
    const getRewardOrder = () => {
        setLoading(true);
        axios
            .get(`/api/v1/reward-orders/${match.params.id}`)
            .then(({ data }) => {
                setRewardOrderData(data.data);
                setLoading(false);
                getCustomerNotes(data.data.customer_id);
                setActionSlugs(data.data.actions);
                // generateActions(data.data.actions);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting Reward Order.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getRewardOrder();
    }, []);

    useEffect(() => {
        generateActions(actionSlugs);
    }, [actionSlugs]);

    const handleAccept = () => {
        setUpdateLoading(true);

        axios
            .put(`/api/v1/reward-orders/accept`, [rewardOrderData.id])
            .then(({ data }) => {
                getRewardOrder();
                addToast({
                    type: "success",
                    content: "Successfully accepted",
                    showFor: 5000,
                });
                setUpdateLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error accepting",
                    showFor: 5000,
                });
                setUpdateLoading(false);
            });
    };

    const handleDeny = () => {
        setUpdateLoading(true);

        axios
            .put(`/api/v1/reward-orders/deny`, [rewardOrderData.id])
            .then(({ data }) => {
                getRewardOrder();
                addToast({
                    type: "success",
                    content: "Successfully denied",
                    showFor: 5000,
                });
                setUpdateLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error denying",
                    showFor: 5000,
                });
                setUpdateLoading(false);
            });
    };

    const handleResend = () => {
        setUpdateLoading(true);

        axios
            .put(`/api/v1/reward-orders/resend`, [rewardOrderData.id])
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: "Successfully resent",
                    showFor: 5000,
                });
                setUpdateLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error resending",
                    showFor: 5000,
                });
                setUpdateLoading(false);
            });
    };

    const handleDispatch = () => {
        setUpdateLoading(true);

        axios
            .put(`/api/v1/reward-orders/dispatch`, [rewardOrderData.id])
            .then(({ data }) => {
                getRewardOrder();
                addToast({
                    type: "success",
                    content: "Successfully dispatched",
                    showFor: 5000,
                });
                setUpdateLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error dispatching",
                    showFor: 5000,
                });
                setUpdateLoading(false);
            });
    };

    const handleNote = () => {
        setCustomerNoteLoading(true);
        setCustomerNote("");
        axios
            .post(`/api/v1/customers/${rewardOrderData.customer_id}/note`, {
                note: customerNote,
            })
            .then(({ data }) => {
                getCustomerNotes(rewardOrderData.customer_id);
            })
            .catch((e) => {
                setCustomerNoteLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error adding a customer note.",
                    showFor: 5000,
                });
            });
    };

    const handleDeleteNote = (id) => {
        setCustomerNoteLoading(true);
        axios
            .delete(
                `/api/v1/customers/${rewardOrderData.customer_id}/note/${id}`
            )
            .then(({ data }) => {
                getCustomerNotes(rewardOrderData.customer_id);
            })
            .catch((e) => {
                setCustomerNoteLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error deleting a customer note.",
                    showFor: 5000,
                });
            });
    };

    return (
        <>
            <PageTitle
                title={
                    rewardOrderData
                        ? `${rewardOrderData.business_name} - ${rewardOrderData.order_type} order ${rewardOrderData.id}`
                        : `Loading Reward`
                }
                expanded
            >
                <div>
                    {!updateLoading && (
                        <>
                            <ButtonDropdown
                                onClick={() => {
                                    history.push("/rewards/orders");
                                }}
                                actions={[]}
                                name="primary"
                            >
                                <FontAwesomeIcon icon={faCaretLeft} /> Back to
                                Reward Orders
                            </ButtonDropdown>{" "}
                            <ButtonDropdown
                                onClick={() => {
                                    history.push(
                                        "/companies/" +
                                            rewardOrderData.customer_id
                                    );
                                }}
                                actions={[]}
                                name="primary"
                            >
                                <FontAwesomeIcon icon={faCaretLeft} /> Back to
                                Company
                            </ButtonDropdown>{" "}
                            {rewardOrderData &&
                                !!rewardOrderData.actions.length && (
                                    <span style={{ marginRight: "1rem" }}>
                                        <ButtonDropdown
                                            onClick={actionFunction(
                                                rewardOrderData.actions[0]
                                            )}
                                            name="primary"
                                            actions={actions}
                                        >
                                            <span
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {rewardOrderData.actions[0]}
                                            </span>
                                        </ButtonDropdown>
                                    </span>
                                )}
                        </>
                    )}
                </div>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && rewardOrderData && (
                <RewardOrderContainer>
                    <Row>
                        <Column style={{ display: "flex" }}>
                            <HalfColumn>
                                <StyledPanel>
                                    <PanelHeader>
                                        Reward Order Details
                                    </PanelHeader>
                                    <PanelBody>
                                        <DetailsTable style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <BoldTd>ID:</BoldTd>
                                                    <td>
                                                        {rewardOrderData.id}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>Perks Type:</BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.perks_type
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>
                                                        Redemption Type:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.order_type
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>
                                                        Allocated Points:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.allocated_points
                                                        }
                                                    </td>
                                                </tr>
                                                {rewardOrderData.reference && (
                                                    <tr>
                                                        <BoldTd>
                                                            Reference:
                                                        </BoldTd>
                                                        <td>
                                                            {
                                                                rewardOrderData.reference
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <BoldTd>Status:</BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.display_status
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>Order Date:</BoldTd>
                                                    <td>
                                                        {new Date(
                                                            rewardOrderData.ordered_at
                                                        ).toLocaleString()}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>
                                                        Expiry Date:
                                                    </BoldTd>
                                                    <td>
                                                        {rewardOrderData.expires_at
                                                            ? new Date(
                                                                  rewardOrderData.expires_at
                                                              ).toLocaleString()
                                                            : "-"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </DetailsTable>
                                    </PanelBody>
                                </StyledPanel>

                                {rewardOrderData.order_type === "amazon" && (
                                    <StyledPanel>
                                        <PanelHeader>
                                            Amazon Voucher Details
                                        </PanelHeader>
                                        {rewardOrderData.order_status ===
                                        "99" ? (
                                            <ViewAmazonOrder
                                                rewardOrderData={
                                                    rewardOrderData
                                                }
                                            />
                                        ) : (
                                            <Row>
                                                <Column
                                                    style={{
                                                        margin: "10px 0 0",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <p>
                                                        Amazon voucher code is
                                                        only available once
                                                        dispatched.
                                                    </p>
                                                </Column>
                                            </Row>
                                        )}
                                    </StyledPanel>
                                )}

                                {rewardOrderData.order_type === "credit" && (
                                    <StyledPanel>
                                        <PanelHeader>
                                            Credit Voucher Details
                                        </PanelHeader>
                                        {rewardOrderData.order_status === "0" ||
                                        rewardOrderData.order_status ===
                                            "99" ? (
                                            <ViewCreditOrder
                                                rewardOrderData={
                                                    rewardOrderData
                                                }
                                            />
                                        ) : (
                                            <Row>
                                                <Column
                                                    style={{
                                                        margin: "10px 0 0",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <p>
                                                        Credit voucher code is
                                                        only available once
                                                        accepted.
                                                    </p>
                                                </Column>
                                            </Row>
                                        )}
                                    </StyledPanel>
                                )}
                            </HalfColumn>
                            <HalfColumn>
                                <StyledPanel>
                                    <PanelHeader>Customer Summary</PanelHeader>
                                    <PanelBody>
                                        <DetailsTable style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <BoldTd>Customer ID</BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.customer_id
                                                        }
                                                        <Link
                                                            to={`/companies/${rewardOrderData.customer_id}`}
                                                            style={{
                                                                marginLeft:
                                                                    "1em",
                                                            }}
                                                        >
                                                            <Button>
                                                                View
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                {!!rewardOrderData.trade_team_id && (
                                                    <tr>
                                                        <BoldTd>
                                                            Distrigo Number
                                                        </BoldTd>
                                                        <td>
                                                            {
                                                                rewardOrderData.trade_team_id
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <BoldTd>Type:</BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.customer_type
                                                        }
                                                    </td>
                                                </tr>
                                                {rewardOrderData.total_points && (
                                                    <tr>
                                                        <BoldTd>
                                                            Current Total
                                                            Points:
                                                        </BoldTd>
                                                        <td>
                                                            {
                                                                rewardOrderData.total_points
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                {rewardOrderData.redeemed_points && (
                                                    <tr>
                                                        <BoldTd>
                                                            Redeemed Points:
                                                        </BoldTd>
                                                        <td>
                                                            {
                                                                rewardOrderData.redeemed_points
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                {rewardOrderData.available_points && (
                                                    <tr>
                                                        <BoldTd>
                                                            Available Points:
                                                        </BoldTd>
                                                        <td>
                                                            {
                                                                rewardOrderData.available_points
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <BoldTd>PERKS Tier:</BoldTd>
                                                    <td>
                                                        {rewardOrderData.tier}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>
                                                        Spend this Year:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.spend_this_year
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>
                                                        PERKS Activation Date:
                                                    </BoldTd>
                                                    <td>
                                                        {new Date(
                                                            rewardOrderData.activated_at
                                                        ).toLocaleString()}
                                                    </td>
                                                </tr>
                                                {rewardOrderData.chosen_dealer_name && (
                                                    <tr>
                                                        <BoldTd>
                                                            Chosen Dealer:
                                                        </BoldTd>
                                                        <td>
                                                            {
                                                                rewardOrderData.chosen_dealer_name
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <BoldTd>
                                                        Business name:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.business_name
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>
                                                        Contact name:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.contact_name
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>
                                                        Contact surname:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.contact_surname
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>
                                                        Email address:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            rewardOrderData.email_address
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <BoldTd>Telephone:</BoldTd>
                                                    <td>
                                                        {rewardOrderData.phone}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </DetailsTable>
                                    </PanelBody>
                                </StyledPanel>
                                <StyledPanel>
                                    <PanelHeader>Customer Notes</PanelHeader>
                                    <PanelBody style={{ padding: "1rem" }}>
                                        {customerNoteLoading ? (
                                            <CubeLoader
                                                size={20}
                                                margin="1rem"
                                            />
                                        ) : (
                                            <>
                                                {customerNoteData &&
                                                customerNoteData.length ? (
                                                    <table
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <tbody>
                                                            {customerNoteData.map(
                                                                (
                                                                    note,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <BoldTd>
                                                                            {note.trade_team_user_name
                                                                                ? `${note.trade_team_user_name} `
                                                                                : ""}
                                                                            {new Date(
                                                                                note.created_at
                                                                            ).toDateString()}
                                                                            :
                                                                        </BoldTd>
                                                                        <td>
                                                                            {
                                                                                note.note
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <IconTextButton
                                                                                onClick={() =>
                                                                                    handleDeleteNote(
                                                                                        note.id
                                                                                    )
                                                                                }
                                                                                icon={
                                                                                    faTrash
                                                                                }
                                                                                showCircle={
                                                                                    false
                                                                                }
                                                                                expanded={
                                                                                    false
                                                                                }
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>No customer notes</p>
                                                )}
                                            </>
                                        )}
                                        <div
                                            style={{
                                                paddingTop: "1rem",
                                                display: "flex",
                                            }}
                                        >
                                            <div style={{ width: "100%" }}>
                                                <InputField
                                                    name="customer_note"
                                                    value={customerNote}
                                                    style={{
                                                        marginBottom: "0",
                                                    }}
                                                    onChange={({ value }) =>
                                                        setCustomerNote(value)
                                                    }
                                                />
                                            </div>
                                            <Button
                                                onClick={() => handleNote()}
                                                style={{ marginLeft: "1em" }}
                                            >
                                                Create
                                            </Button>
                                        </div>
                                    </PanelBody>
                                </StyledPanel>
                            </HalfColumn>
                        </Column>
                    </Row>
                </RewardOrderContainer>
            )}
        </>
    );
};

export default ViewRewardOrder;
